// import logo from './logo.svg';
import './App.css';
// import KateSpringPhoto from './assets/obsession-method-KateSpringLarge.jpg'
import PDFCover from './assets/kate-spring-cover-wide.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>Read this <span className="italics underline">FREE</span> Book...</h2>
        <h1>Hack Her Brain And Make Her Yours</h1>
        <form>
          <input type="email" placeholder="Enter valid email here"></input>
          <button id="sendBtn" disabled type="submit" className="btn-primary">GET MY FREE BOOK</button>
        </form>
        <img className="images" alt="cover" src={PDFCover}></img>
        <p>This is a FREE service from Kate Spring. <br></br>Credit card NOT required. </p>
        <p>Your information is 100% secure with us and will never be shared with anyone.</p>
        <p className="footer-copyright">© 2023 - Privacy Policy / Terms of Service / Disclaimer / Support</p>
      </header>
    </div>
  );
}

export default App;
